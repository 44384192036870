<template>
    <v-card
        tile
        flat
        :loading="loading"
    >

        <div
            v-if="previewUrl"
            class="mb-4"
        >
            <youtube-view
                :item="previewUrl"
            />
        </div>
        <w-simple-form
            :disabled="loading"
            :fields="formFields"
            @input:url="onInputLink"
            @submit="onSubmit"
        />
    </v-card>
</template>

<script>
import { required } from '@/utils/validation'
import { uploadUrl } from '@apps/media/api'
import uploadFormMixin from '@apps/media/components/MediaUploadForm/mixins/uploadFormMixin'
import YoutubeView from '@apps/media/components/MediaView/YoutubeView'
import { isYoutubeLink } from '@apps/media/tools'

export default {
    name: 'UploadYoutubeForm',
    components: { YoutubeView },
    mixins: [ uploadFormMixin ],
    computed: {
        formFields() {
            return [
                {
                    type: 'v-text-field',
                    name: 'url',
                    props: {
                        label: this.$trans('Link'),
                        disabled: this.loading,
                        outlined: true,
                        dense: true,
                        rules: [
                            required,
                            v => isYoutubeLink(v) || this.$trans('validation.url')
                        ]
                    }
                }
            ]
        }
    },
    data() {
        return {
            loading: false,
            errors: {},
            previewUrl: null
        }
    },
    methods: {
        onInputLink(src) {
            if(isYoutubeLink(src)) {
                this.previewUrl = { src }
            } else {
                this.previewUrl = null
            }
        },
        async onSubmit(data) {
            this.loading = true

            try {
                const payload = {
                    ...data
                }

                if(this.parent) {
                    payload.parent = this.parent
                }

                const response = await uploadUrl(payload)

                this.$emit('success', response.data)
            } catch (error) {
                this.errors = error.response &&
                error.response.data &&
                error.response.data.errors
                    ? error.response.data.errors
                    : {}
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang=scss>

</style>
